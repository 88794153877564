import React from 'react'
import apiintegration from '../../images/simple-api.png'

const Integration = () => {
  return (
    <section className="typical__section typical__padding" id="integration">
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="colorized">Simple API</span>
                    <br />
                    <span className="default">Integration</span>
                  </h2>
                  <p className="description">
                    Get your payment setup in one day.
                  </p>
                  <div className="temporary">
                    <button
                      type="button"
                      className="digimeth-btn xl default-btn"
                      // disabled
                    >
                      Developers Platform
                    </button>
                    <span className="soon-btn">soon</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="right">
              <picture className="fluid__wrapper">
                <img alt="Integration" src={apiintegration} />
              </picture>
            </div>
          </div>
        </section>
  )
}

export default Integration