import React from 'react'
import {AlterPaymentsAnimation} from "../../ui/AntiFraudAnimation";



// import scan from '../../images/scan.svg'
// import cloud from '../../images/cloud-add.svg'
// import fraudscore from '../../images/fraud-score.svg'
// import decision from '../../images/decision.svg'
// import { motion } from 'framer-motion'

const Secure = () => {
  return (
    <section className="typical__section typical__padding" id="secure">
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="colorized">Anti Fraud</span>
                    <br />
                    <span className="default">System</span>
                  </h2>

                  <p className="description">
                    Recognize and reduce fraud using data-driven engine. Digimeth
                    antifraud system working without harming frictionless loyal
                    customer with database over 50 mln worldwide users.
                  </p>

                  <ul className="detailed--list">
                    <li>Rich analytics on fraud performance</li>
                    <li>Custom built engine to suit your business</li>
                    <li>Transaction score system</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="right">
              <AlterPaymentsAnimation/>
              {/* <motion.div
                className="scan__wrapper"
                initial={{ y: 40, opacity: 0 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5 }}
                viewport={{ once: true }}
              >
                <picture>
                  <img alt="scan" src={scan} />
                </picture>
              </motion.div>

              <motion.div
                className="cloud__wrapper"
                initial={{ y: 40, opacity: 0 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5 }}
                viewport={{ once: true }}
              >
                <picture>
                  <img alt="cloud" src={cloud} />
                </picture>
              </motion.div>

              <motion.picture
                className="fraud-score__wrapper"
                initial={{ y: 40, opacity: 0 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5 }}
                viewport={{ once: true }}
              >
                <img alt="fraud-score" src={fraudscore} />
                <p>Fraud Score</p>
              </motion.picture>

              <motion.picture
                className="decision__wrapper"
                initial={{ y: 40, opacity: 0 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5 }}
                viewport={{ once: true }}
              >
                <img alt="decision" src={decision} />
                <p>Decision</p>
              </motion.picture> */}
            </div>
          </div>
        </section>
  )
}

export default Secure