import React from 'react'
import subscription from '../../images/img-subscription.png'

const Subscription = () => {
  return (
    <section
    className="typical__section typical__padding"
    id="subscription"
  >
    <div className="site__container grid">
      <div className="left">
        <div className="content--wrapper">
          <div className="content">
            <h2>
              <span className="colorized">Subscription</span>
              <br />
              <span className="default">Model</span>
            </h2>
            <p className="description">
              Spend more time running your business than worrying if your
              customers will stay signed up.
            </p>
            <p className="description small">
              Get detailed information about your subscriptions with ML
              algorithm that help you reach your best LTV and predict your
              cashflow.
            </p>
            <a
              href="#"
              target="_blank"
              rel="noreferrer"
            >
              <button
                type="button"
                className="digimeth-btn xl secondary-btn"
              >
                Get Demo
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="right">
        <picture className="fluid__wrapper">
          <img alt="integration" src={subscription} />
        </picture>
      </div>
    </div>
  </section>
  )
}

export default Subscription