import React, {useEffect, useRef, useState} from "react";
import antiFraud1 from "../images/anti-fraud-1.png";
import antiFraud2 from "../images/anti-fraud-2.png";
import antiFraud3 from "../images/anti-fraud-3.png";
import "./styles.css";


export const AlterPaymentsAnimation = () => {
    const images = [antiFraud3, antiFraud2, antiFraud1];
    const [index, setIndex] = useState(0);
    const delay = 3000;
    const timeoutRef = useRef(null);

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setIndex((prevIndex) =>
                    prevIndex === images.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );

        return () => {
            resetTimeout();
        };
    }, [index]);
    return (
        <picture className="fluid__wrapper anti-fraud-preview-wrapper">
            {images.map((image, idx) => (
                <img key={idx} alt="Integration" src={image} className={`anti-fraud-preview ${index === idx ? "active" : ""}`}/>
            ))}
        </picture>
    );
}