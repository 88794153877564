import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Featured from "../components/sections/Featured"
import Contact from "../components/sections/Contact"
import AlternativeMethods from "../components/sections/AlternativeMethods"
import CustomCard from "../components/sections/CustomCard"
import Payment from "../components/sections/Payment"
import Analytics from "../components/sections/Analytics"
import Support from "../components/sections/Support"
import Integration from "../components/sections/Integration"
import Subscription from "../components/sections/Subscription"
import Secure from "../components/sections/Secure"
import Hero from "../components/sections/Hero"

const IndexPage = () => {
  return (
    <>
      <SEO />
      <Layout>
        <Hero/>
        <Secure/>
        <Subscription/>
        <Integration/>
        <Support/>
        <Analytics/>
        <Payment/>
        <CustomCard/>
        <AlternativeMethods/>
        <Featured title="Our Features" />
        <Contact/>
      </Layout>
    </>
    
  )
}

export default IndexPage
