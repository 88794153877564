import React from 'react'
import applepay from '../../images/applepay.svg'
import googlepay from '../../images/googlepay.svg'
import alipay from '../../images/alipay.svg'
import paypall from '../../images/paypall.svg'
import klarna from '../../images/klarna.svg'
import VerticalCarousel from '../../ui/VerticalCarousel'

const AlternativeMethods = () => {
  return (
    <section
          className="typical__section typical__padding"
          id="alternative-methods"
        >
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="colorized">Alternative</span>
                    <br />
                    <span className="default">Payment Method</span>
                  </h2>
                  <p className="description ">
                    Integrate any payment methods you want.
                  </p>
                  <ul className="detailed--list simple">
                    <li>- Flexible settings</li>
                    <li>- Region segmentation</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="right">
              <div className="fluid__wrapper paymentMethodContainer">
                <VerticalCarousel
                  data={[
                    { icon: applepay, text: "Apple Pay", status: true },
                    { icon: googlepay, text: "Google Pay", status: false },
                    { icon: alipay, text: "Alipay", status: true },
                    { icon: paypall, text: "Pay Pall", status: false },
                    { icon: klarna, text: "Klarna", status: true },
                    { icon: applepay, text: "Apple Pay", status: true },
                    { icon: googlepay, text: "Google Pay", status: false },
                    { icon: alipay, text: "Alipay", status: true },
                    { icon: paypall, text: "Pay Pall", status: false },
                    { icon: klarna, text: "Klarna", status: true },
                  ]}
                />
              </div>
            </div>
          </div>
        </section>
  )
}

export default AlternativeMethods