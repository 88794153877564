import React from "react";
import { Link } from "gatsby";

import imagehero from "../../images/navigation.png";

const Hero = () => {
  const serviceId = "contact-us";
  return (
    <section id="hero">
      <div className="site__container">
        <div className="top typical__padding">
          <h1>
            <span className="colorized">Multiple Payment </span>
            Methods
          </h1>
          <p className="hero-desc">
            We will find the perfect match for your business - from subscription
            model to alternative payments!
          </p>
          <div className="connections">
            {/* <Link  to={`/about#${serviceId}`} >
                  <button type="button" className="digimeth-btn default-btn xl">
                    Contact us
                  </button>
                </Link> */}
            <Link to="/contact">
              <button type="button" className="digimeth-btn default-btn xl">
                Get Started
              </button>
            </Link>
          </div>
        </div>
        <div className="image__container">
          <img src={imagehero} alt="dashboard" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
