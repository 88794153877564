import React, { useState } from "react";
import cn from "classnames";
import Switch from "@mui/material/Switch";
import "./styles.css";

const VerticalCarousel = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const halfwayIndex = Math.ceil(data.length / 2);
  const itemHeight = 88;
  const shuffleThreshold = halfwayIndex * itemHeight;
  const visibleStyleThreshold = shuffleThreshold / 2;
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setActiveIndex((prevIndex) =>
          prevIndex === data.length - 1 ? 0 : prevIndex + 1
        ),
      2000
    );

    return () => {
      resetTimeout();
    };
  }, [activeIndex]);

  const determinePlacement = (itemIndex) => {
    if (activeIndex === itemIndex) return 0;

    if (itemIndex >= halfwayIndex) {
      if (activeIndex > itemIndex - halfwayIndex) {
        return (itemIndex - activeIndex) * itemHeight;
      } else {
        return -(data.length + activeIndex - itemIndex) * itemHeight;
      }
    }

    if (itemIndex > activeIndex) {
      return (itemIndex - activeIndex) * itemHeight;
    }

    if (itemIndex < activeIndex) {
      if ((activeIndex - itemIndex) * itemHeight >= shuffleThreshold) {
        return (data.length - (activeIndex - itemIndex)) * itemHeight;
      }
      return -(activeIndex - itemIndex) * itemHeight;
    }
  };

  return (
    <div className="slides">
      <div className="carousel-inner">
        {data.map(({ icon, text, status }, i) => (
          <div
            className={cn("carousel-item", {
              active: activeIndex === i,
              visible: Math.abs(determinePlacement(i)) <= visibleStyleThreshold,
            })}
            key={i}
            style={{
              transform: `translateY(${determinePlacement(i)}px)`,
            }}
          >
            <div className="paymentName">
              <img src={icon} alt="iconpay"/>
              <p>{text}</p>
            </div>
            <Switch defaultChecked={status} color="success" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default VerticalCarousel;
